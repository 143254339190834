import React from 'react';

import { useLocation } from 'react-router-dom';
import LatestArticleCell from './LatestArticleCell';
import './RecommendedArticles.css';
import blogData from '../../styles/Screens/BlogPageScreen/blogData';

const RecommendedArticles = () => {
    const location = useLocation();

    const extractIdFromUrl = () => {
        const match = location.pathname.match(/\/id\/(\d+)/);
        return match ? parseInt(match[1], 10) : null;
    };

    const currentId = extractIdFromUrl();

    const filteredArticles = Object.values(blogData)
    .filter(article => article.id !== currentId) 
    .sort(() => Math.random() - 0.5);

    return (
        <div className="recommended-articles-container">
            <div className="recommended-articles-grid">
                {filteredArticles.slice(0, 3).map((article, index) => (
                    <LatestArticleCell
                        key={article.id}
                        id={article.id}
                        imageSrc={article.leftImage}
                        label={article.tag}
                        title={article.mainTitle}
                        description={article.description}
                    />
                ))}
            </div>
        </div>
    );
};

export default RecommendedArticles;