import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/components/TopNavigationBar.css';
import SecondaryButton from '../buttons/SecondaryButton';
import MobileTopNavigationBar from './MobileTopNavigationBar';
import DropdownMenu from '../dropdownMenu/DropdownMenu';
import { ShoppingBag } from 'react-feather';
import { useCart } from '../../providers/CartContext';
import { useAuth } from '../../services/authorization/authContext';

const DesktopTopNavigationBar = () => {
    const { itemsInBasket } = useCart();
    const { user, loading } = useAuth();

    if (user) {
        return (
            <nav className="DesktopTopNavigationBar">
                <div className="navbar-logo">
                    <Link to="/dashboard"><img src="/logo-default.svg" alt="Shape Factor Logo" className="logo"></img></Link>
                </div>
                <div className="navbar-links">
                    <p className='text-s medium'><Link to="/dashboard">Programy</Link></p>
                    <p className='text-s medium'><Link to="/shop">Sklep</Link></p>
                    <p className='text-s medium'><Link to="/blog">Blog</Link></p>
                    <DropdownMenu />
                    <div className="cart-icon-container">
                        <Link to="/basket" className="cart-link">
                            <ShoppingBag size={24} />
                            {itemsInBasket > 0 && (
                                <span className="bag-badge text-s semibold">{itemsInBasket}</span>
                            )}
                        </Link>
                    </div>
                </div>
            </nav>
        );
    } else {
        return (
            <nav className="DesktopTopNavigationBar">
                <div className="navbar-logo">
                    <Link to="/"><img src="/logo-default.svg" alt="Shape Factor Logo" className="logo"></img></Link>
                </div>
                <div className="navbar-links">
                    <p className='text-s medium'><Link to="/shop">Sklep</Link></p>
                    <div className="cart-icon-container">
                        <Link to="/basket" className="cart-link">
                            <ShoppingBag size={24} />
                            {itemsInBasket > 0 && (
                                <span className="bag-badge text-s semibold">{itemsInBasket}</span>
                            )}
                        </Link>
                    </div>
                </div>
            </nav>
        )
    }
};


const TopNavigationBar = () => {

    return (
        <div>
            <DesktopTopNavigationBar />
            <MobileTopNavigationBar />
        </div>
    )
}

export default TopNavigationBar