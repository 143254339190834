import React from "react";
import LatestArticleCell from "../LatestArticleCell/LatestArticleCell";
import "./LatestArticleGrid.css"; 
import blogData from "../../styles/Screens/BlogPageScreen/blogData";

const LatestArticleGrid = ({ articles = blogData }) => {
    return (
        <div className="latest-article-grid">
            {Object.values(articles).map((article, index) => (
                <LatestArticleCell
                    key={index}
                    id={article.id}
                    imageSrc={article.leftImage}
                    label={article.tag}
                    title={article.mainTitle}
                    description={article.description}
                />
            ))}
        </div>
    );
};
export default LatestArticleGrid;